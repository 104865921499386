<template>
  <v-container class="px-0">
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="px-0">
          <div class="top-banner d-flex justify-space-between align-center">
            <v-img
              max-width="150"
              min-height="269"
              max-height="269"
              :src="`${baseURL}assets/images/Intersect.jpg`"
            >
            </v-img>
            <div class="top-banner-right">
              <div class="cross-logo d-flex">
                <v-img
                  class="my-auto mr-2"
                  max-width="35"
                  :src="`${baseURL}assets/images/large_logo.png`"
                >
                </v-img>
                <v-img
                  class="my-auto"
                  :src="`${baseURL}assets/images/cross-point.png`"
                >
                </v-img>
              </div>
              <div>
                <h4 class="text--primary">
                  飲食特化型<br />会計・税務サービス
                </h4>
                <ul class="list-speciality">
                  <li class="text--primary font-Roboto">
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.84209 11.8636C5.21 12.2753 6.07969 12.1885 6.20351 11.5763C6.9735 7.77596 10.0287 4.6903 12.3707 1.7558C13.02 0.942633 11.6642 0.071372 11.0232 0.874892C8.88308 3.55618 6.21462 6.35673 5.059 9.68254C3.98924 8.47383 2.91438 7.27583 1.70055 6.20001C0.931832 5.51844 -0.27031 6.5912 0.506696 7.27995C2.08815 8.68212 3.43489 10.2923 4.84209 11.8636Z"
                        fill="#20368B"
                      />
                    </svg>
                    <span>飲食店専門</span>
                  </li>
                  <li class="text--primary font-Roboto">
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.84209 11.8636C5.21 12.2753 6.07969 12.1885 6.20351 11.5763C6.9735 7.77596 10.0287 4.6903 12.3707 1.7558C13.02 0.942633 11.6642 0.071372 11.0232 0.874892C8.88308 3.55618 6.21462 6.35673 5.059 9.68254C3.98924 8.47383 2.91438 7.27583 1.70055 6.20001C0.931832 5.51844 -0.27031 6.5912 0.506696 7.27995C2.08815 8.68212 3.43489 10.2923 4.84209 11.8636Z"
                        fill="#20368B"
                      />
                    </svg>
                    <span>融資に強い</span>
                  </li>
                  <li class="text--primary font-Roboto">
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.84209 11.8636C5.21 12.2753 6.07969 12.1885 6.20351 11.5763C6.9735 7.77596 10.0287 4.6903 12.3707 1.7558C13.02 0.942633 11.6642 0.071372 11.0232 0.874892C8.88308 3.55618 6.21462 6.35673 5.059 9.68254C3.98924 8.47383 2.91438 7.27583 1.70055 6.20001C0.931832 5.51844 -0.27031 6.5912 0.506696 7.27995C2.08815 8.68212 3.43489 10.2923 4.84209 11.8636Z"
                        fill="#20368B"
                      />
                    </svg>
                    <span>業界最安かつスピーディー</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </v-card>

        <div class="cp-second-sect">
          <v-img
            class="image-bg-cp-snd-sec"
            :src="`${baseURL}assets/images/large_logo_bg.png`"
            max-height="295"
            max-width="230"
            height="295"
            width="230"
          >
          </v-img>
          <v-btn
            color="primary"
            block
            class="py-7 font-Noto-Sans cross-point-btn-large"
            >Cross Point会計・税務サービスのご案内</v-btn
          >
          <div class="text-cp-inr text-center">
            <h3 class="font-Roboto">Cross Pointをもっと活用して</h3>
            <h3 class="font-Roboto second">売上管理をシンプルにしませんか？</h3>
          </div>

          <div
            class="account-word"
            :style="`--background: url(${baseURL}assets/images/man-shape.svg)`"
          >
            <p class="font-Noto-Sans">
              経理業務に関して このようなお悩みは ありませんか？
            </p>
          </div>
          <v-card
            class="d-flex align-center account-work-card"
            min-height="230"
          >
            <ul class="account-work-blw-list">
              <li>
                <p>
                  <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.97685 11.6761C5.34476 12.0878 6.21445 12.001 6.33828 11.3888C7.10827 7.58846 10.1634 4.5028 12.5055 1.5683C13.1548 0.755133 11.799 -0.116128 11.158 0.687392C9.01785 3.36868 6.34938 6.16923 5.19377 9.49504C4.12401 8.28633 3.04915 7.08833 1.83531 6.01251C1.0666 5.33094 -0.135545 6.4037 0.641462 7.09245C2.22292 8.49462 3.56966 10.1048 4.97685 11.6761Z"
                      fill="black"
                    />
                  </svg>
                  <span>帳簿で管理する暇がない</span>
                </p>
              </li>
              <li>
                <p>
                  <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.97685 11.6761C5.34476 12.0878 6.21445 12.001 6.33828 11.3888C7.10827 7.58846 10.1634 4.5028 12.5055 1.5683C13.1548 0.755133 11.799 -0.116128 11.158 0.687392C9.01785 3.36868 6.34938 6.16923 5.19377 9.49504C4.12401 8.28633 3.04915 7.08833 1.83531 6.01251C1.0666 5.33094 -0.135545 6.4037 0.641462 7.09245C2.22292 8.49462 3.56966 10.1048 4.97685 11.6761Z"
                      fill="black"
                    />
                  </svg>
                  <span>項目が複雑でよくわからない</span>
                </p>
              </li>
              <li>
                <p>
                  <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.97685 11.6761C5.34476 12.0878 6.21445 12.001 6.33828 11.3888C7.10827 7.58846 10.1634 4.5028 12.5055 1.5683C13.1548 0.755133 11.799 -0.116128 11.158 0.687392C9.01785 3.36868 6.34938 6.16923 5.19377 9.49504C4.12401 8.28633 3.04915 7.08833 1.83531 6.01251C1.0666 5.33094 -0.135545 6.4037 0.641462 7.09245C2.22292 8.49462 3.56966 10.1048 4.97685 11.6761Z"
                      fill="black"
                    />
                  </svg>
                  <span>帳簿で管理する暇がない</span>
                </p>
              </li>
              <li>
                <p>
                  <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.97685 11.6761C5.34476 12.0878 6.21445 12.001 6.33828 11.3888C7.10827 7.58846 10.1634 4.5028 12.5055 1.5683C13.1548 0.755133 11.799 -0.116128 11.158 0.687392C9.01785 3.36868 6.34938 6.16923 5.19377 9.49504C4.12401 8.28633 3.04915 7.08833 1.83531 6.01251C1.0666 5.33094 -0.135545 6.4037 0.641462 7.09245C2.22292 8.49462 3.56966 10.1048 4.97685 11.6761Z"
                      fill="black"
                    />
                  </svg>
                  <span>項目が複雑でよくわからない</span>
                </p>
              </li>
              <li>
                <p>
                  <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.97685 11.6761C5.34476 12.0878 6.21445 12.001 6.33828 11.3888C7.10827 7.58846 10.1634 4.5028 12.5055 1.5683C13.1548 0.755133 11.799 -0.116128 11.158 0.687392C9.01785 3.36868 6.34938 6.16923 5.19377 9.49504C4.12401 8.28633 3.04915 7.08833 1.83531 6.01251C1.0666 5.33094 -0.135545 6.4037 0.641462 7.09245C2.22292 8.49462 3.56966 10.1048 4.97685 11.6761Z"
                      fill="black"
                    />
                  </svg>
                  <span>項目が複雑でよくわからない</span>
                </p>
              </li>
            </ul>
          </v-card>
          <h3 class="cross-idea-top-text font-Noto-Sans text-center mt-10 mb-8">
            有料版のCross Pointでは、<br />
            飲食専門チームが<br />
            あなたの会計・税務・集客を <br />まるっとサポートします。
          </h3>
          <v-img
            :src="`${baseURL}assets/images/idea-shap.svg`"
            class="idea-image mx-auto"
            max-width="254"
            height="188"
          >
          </v-img>
        </div>

        <v-card color="primary" class="px-4 pb-10">
          <h2 class="steps-cross-title font-Noto-Sans text-center">
            Cross Pointの<br />
            会計・税務サービスが<br />
            選ばれる3つの理由
          </h2>
          <div class="cross-steps-outr">
            <div class="step-cross-inr">
              <h4>
                <span class="number">1</span>
                <span class="step-title font-Noto-Sans">業界最安値</span>
              </h4>
              <v-img
                :src="`${baseURL}assets/images/step1.svg`"
                max-width="98"
                class="mx-auto my-5"
              >
              </v-img>
              <div
                class="d-flex justify-space-between align-center circle-steps-shaps"
              >
                <h5>
                  <span>月額</span>
                  <span>5,000 円～</span>
                </h5>
                <h5>
                  <span>月額</span>
                </h5>
                <h5>
                  <span>月額</span>
                  <span>5,000 円～</span>
                </h5>
              </div>
            </div>
          </div>
          <div class="cross-steps-outr">
            <div class="step-cross-inr">
              <h4>
                <span class="number">2</span>
                <span class="step-title font-Noto-Sans"
                  >正確な分析、的確なアドバイス</span
                >
              </h4>
              <v-img
                :src="`${baseURL}assets/images/step2.svg`"
                max-width="98"
                class="mx-auto my-5"
              >
              </v-img>
              <div
                class="d-flex justify-space-between align-center circle-steps-shaps"
              >
                <h5>
                  <span>飲食専門<br />チーム</span>
                </h5>
                <h5>
                  <span>月額</span>
                  <span>5,000 円～</span>
                </h5>
                <h5>
                  <span>月額</span>
                  <span>5,000 円～</span>
                </h5>
              </div>
            </div>
          </div>
          <div class="cross-steps-outr">
            <div class="step-cross-inr">
              <h4>
                <span class="number">3</span>
                <span class="step-title font-Noto-Sans">豊富なオプション</span>
              </h4>
              <v-img
                :src="`${baseURL}assets/images/step3.svg`"
                max-width="98"
                class="mx-auto my-5"
              >
              </v-img>
              <div
                class="d-flex justify-space-between align-center circle-steps-shaps"
              >
                <h5>
                  <span>月額</span>
                  <span>5,000 円～</span>
                </h5>
                <h5>
                  <span>月額</span>
                  <span>5,000 円～</span>
                </h5>
                <h5>
                  <span>月額</span>
                  <span>5,000 円～</span>
                </h5>
              </div>
            </div>
          </div>
          <v-btn block class="step-btn-after py-5 font-Noto-Sans mb-5"
            >無料で相談する</v-btn
          >
        </v-card>
        <div class="bg-color-theme">
          <div class="cp-second-sect cp-stepper">
            <v-img
              class="image-bg-cp-snd-sec"
              :src="`${baseURL}assets/images/large_logo_bg.png`"
              max-height="295"
              max-width="230"
              height="295"
              width="230"
            >
            </v-img>

            <div class="text-cp-inr text-center">
              <h4 class="title1 font-Noto-Sans mb-2">
                サービス利用者のみの充実機能！
              </h4>
              <h3 class="border-underline font-Noto-Sans">
                Cross Pointアプリ<span>では</span>
              </h3>
              <h3
                class="font-Noto-Sans mx-auto d-flex align-center justify-center mb-2"
              >
                <span>会計ソフト</span>
                <v-img
                  :src="`${baseURL}assets/images/company-freee.svg`"
                  max-width="90"
                  max-height="41"
                ></v-img>
                <span>と連携し</span>
              </h3>
              <h3 class="font-Noto-Sans mb-9">スマホから売上管理が可能に</h3>
              <p class="font-Roboto mb-10 pb-5">
                Cross Pointの会計・財務サービスに ご契約いただくと、Cross
                Pointアプリの 便利な機能をお使い頂けるようになります。
              </p>
              <v-btn
                color="primary"
                block
                class="py-7 mt-5 font-Noto-Sans cross-point-btn-large arrow"
                >年間売上目標を入力するだけで</v-btn
              >
            </div>
          </div>
          <div
            class="mobile-procedure-steps d-flex align-center justify-space-between"
          >
            <v-img
              :src="`${baseURL}assets/images/m1.png`"
              max-width="160px"
              max-height="224"
              height="224"
              class="mobileimg"
            ></v-img>
            <div class="mobile-inr-right mr-auto ml-5">
              <v-img
                :src="`${baseURL}assets/images/1.png`"
                max-width="75"
                height="88"
                class="number-img"
              ></v-img>
              <h3 class="font-Noto-Sans">
                売上目標の<br />
                達成率がわかる
              </h3>
              <p class="font-Roboto">
                ああああああああああああああああああああああああああああああ
              </p>
            </div>
          </div>

          <div
            class="mobile-procedure-steps d-flex align-center justify-space-between reverse"
          >
            <div class="mobile-inr-right mr-auto ml-5">
              <v-img
                :src="`${baseURL}assets/images/2.png`"
                max-width="75"
                height="88"
                class="number-img"
              ></v-img>
              <h3 class="font-Noto-Sans">
                FL比率の推移を記録
              </h3>
              <p class="font-Roboto">
                ああああああああああああああああああああああああああああああ
              </p>
            </div>
            <v-img
              :src="`${baseURL}assets/images/m2.png`"
              max-width="160px"
              max-height="224"
              height="224"
              class="mobileimg"
            ></v-img>
          </div>

          <div
            class="mobile-procedure-steps d-flex align-center justify-space-between"
          >
            <v-img
              :src="`${baseURL}assets/images/m3.png`"
              max-width="160px"
              max-height="224"
              height="224"
              class="mobileimg"
            ></v-img>
            <div class="mobile-inr-right mr-auto ml-5">
              <v-img
                :src="`${baseURL}assets/images/3.png`"
                max-width="75"
                height="88"
                class="number-img"
              ></v-img>
              <h3 class="font-Noto-Sans">売上、コストが<br />一目でわかる</h3>
              <p class="font-Roboto">
                ああああああああああああああああああああああああああああああ
              </p>
            </div>
          </div>

          <v-card class="fee-structure">
            <h2 class="font-Noto-Sans">料金体系</h2>
            <div class="price-fee d-flex align-center justify-center">
              <span class="circle-text font-Noto-Sans">月額</span>
              <h4 class="font-Noto-Sans">12,500<span>円～</span></h4>
            </div>
            <div class="prices-boxes d-flex justify-center">
              <div class="price-box-outr mr-2">
                <div>
                  <h2 class="font-Noto-Sans">売上管理アプリ<br />利用無料</h2>
                </div>
              </div>

              <div class="price-box-outr ml-2">
                <div>
                  <h6 class="font-Noto-Sans">
                    <span>会計ソフト</span><span class="freee-text">freee</span>
                  </h6>
                  <h6 class="font-Noto-Sans text-middle">
                    <span>通常利用料</span>
                  </h6>
                  <h5 class="font-Noto-Sans">
                    <span class="cross-price">2,380</span
                    ><span class="price-symbol">円</span>
                    <img
                      :src="`${baseURL}assets/images/arrow-right.svg`"
                      alt=""
                    />
                    <span class="last-text">無料</span>
                  </h5>
                </div>
              </div>
            </div>

            <div class="price-detail-text font-Noto-Sans">
              <p>※給与計算、年末調整、社会保険手続きは別途お見積り</p>
              <p>
                ※経営状況や作業内容によって、料金が変動する場合がございます。
              </p>
            </div>

            <div class="service-content">
              <h2 class="text-center font-Noto-Sans">サービス内容</h2>

              <div
                class="services-box bluish d-flex justify-space-between align-center"
              >
                <h5 class="font-Noto-Sans">記帳代行</h5>
                <v-img
                  :src="`${baseURL}assets/images/crosspoint_service_img01.png`"
                  max-width="93"
                  max-height="63"
                ></v-img>
              </div>

              <div
                class="services-box d-flex justify-space-between align-center"
              >
                <h5 class="font-Noto-Sans">月次試算表作成</h5>
                <v-img
                  :src="`${baseURL}assets/images/crosspoint_service_img02.png`"
                  max-width="93"
                  max-height="63"
                ></v-img>
              </div>

              <div
                class="services-box bluish d-flex justify-space-between align-center"
              >
                <h5 class="font-Noto-Sans">決算 / 確定申告</h5>
                <v-img
                  :src="`${baseURL}assets/images/crosspoint_service_img03.png`"
                  max-width="93"
                  max-height="63"
                ></v-img>
              </div>

              <div
                class="services-box d-flex justify-space-between align-center"
              >
                <h5 class="font-Noto-Sans">経営相談 / アドバイス</h5>
                <v-img
                  :src="`${baseURL}assets/images/crosspoint_service_img04.png`"
                  max-width="93"
                  max-height="63"
                ></v-img>
              </div>

              <v-btn
                height="54"
                color="primary"
                block
                class="service-btn-large font-Noto-Sans"
                >無料で相談する</v-btn
              >
            </div>
          </v-card>

          <div class="tech-section">
            <h2 class="font-Noto-Sans text-center">
              高度なITテクノロジーで<br />
              低コストかつ高付加価値の<br />
              提供を実現します。
            </h2>

            <div class="chat-boxes">
              <div class="chat-box left">
                <p class="font-Roboto">
                  担当者が飲食店のことをしっかり理解しているから、コストダウンと売上UPの両方がかなった！
                </p>
              </div>
              <div class="chat-box right">
                <p class="font-Roboto">
                  スマホで手軽に数字を把握できるので
                  日々の業務に対する意識付けが できるようになった！
                </p>
              </div>
            </div>
            <v-img
              :src="`${baseURL}assets/images/cookers.png`"
              max-width="175"
              max-height="106"
              class="mx-auto"
            ></v-img>
          </div>
          <div class="footer-section">
            <div class="top-logo d-flex align-center">
              <v-img
                max-width="140"
                max-height="29"
                :src="`${baseURL}assets/images/logo-white.png`"
              ></v-img>
              <p class="my-auto ml-3 font-Roboto">
                飲食特化の<br />会計・税務サービス
              </p>
            </div>
            <v-btn
              color="white"
              class="footbtn text--primary mt-5"
              block
              height="54"
              >話を聞いてみる</v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Services",
  data() {
    return {
      baseURL: process.env.BASE_URL
    };
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
